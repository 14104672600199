import { useState } from 'react';
import { startBuild } from './builder';

async function build(form: {
    repoAddr: string;
    buildBranch: string;
    baselineVersion: string;
    changeLog: string;
}) {
    let { repoAddr, buildBranch, baselineVersion, changeLog } = form;
    repoAddr = repoAddr.trim().replace(/\/$/, '');
    buildBranch = buildBranch.trim();
    baselineVersion = baselineVersion.trim();
    changeLog = changeLog.trim();
    if (!repoAddr) {
        alert("请输入制品仓库地址!");
        return;
    }
    if (!buildBranch) {
        alert("请输入构建分支!");
        return;
    }
    if (!baselineVersion) {
        alert("请输入基线版本!");
        return;
    }
    if (!changeLog) {
        alert("请输入发布日志!");
        return;
    }
    startBuild({
        repoAddr,
        buildBranch,
        baselineVersion,
        changeLog
    });
}

function Form() {
    const [repoAddr, setRepoAddr] = useState<string>("http://161.189.207.206:8888");
    const [buildBranch, setBuildBranch] = useState<string>("dev-5.5.3.11-release");
    const [baselineVersion, setBaselineVersion] = useState<string>("5.5.3.11");
    const [changeLog, setChangeLog] = useState<string>("");
    return (
        <div>
            <div>
                <span>制品仓库地址:</span>
                <input value={repoAddr} onChange={(evt) => {
                    setRepoAddr(evt.target.value);
                }} />
            </div>
            <div>
                <span>构建分支:</span>
                <input value={buildBranch} onChange={(evt) => {
                    setBuildBranch(evt.target.value);
                }} />
            </div>
            <div>
                <span>基线版本:</span>
                <input value={baselineVersion} onChange={(evt) => {
                    setBaselineVersion(evt.target.value);
                }} />
            </div>
            <div>
                <span>发布日志:</span>
                <textarea value={changeLog} onChange={(evt) => {
                    setChangeLog(evt.target.value);
                }} rows={8}></textarea>
            </div>
            <button onClick={() => {
                build({
                    repoAddr,
                    buildBranch,
                    baselineVersion,
                    changeLog
                });
            }}>生成</button>
        </div>
    );
}

function App() {
    return (
        <div>
            <div>生成之前请先确保goose-native-tool处于运行状态!</div>
            <Form />
        </div>
    );
}

export default App;


enum MsgType {
    Request = "request",
    Response = "response",
    Notify = "notify"
}

enum NotifyKey {
    Ready = "ready"
}

interface Msg {
    type: MsgType;
    payload: any;
}

/**
 * 把10进制数字转换成62位进制字符串
 * @param {Number} number 10进制数字
 * @return {String} 62位进制字符串
 */
function numToRadix62(number: number): string {
    const chars: string[] = "0123456789abcdefghigklmnopqrstuvwxyzABCDEFGHIGKLMNOPQRSTUVWXYZ".split("");
    const radix = chars.length;
    let qutient = +number;
    const arr: string[] = [];
    do {
        const mod = qutient % radix;
        qutient = (qutient - mod) / radix;
        arr.unshift(chars[mod]);
    } while (qutient);
    return arr.join("");
}

/**
 * id生成方法
 * @return {String} 返回字符串id
 */
function genId(): string {
    const _self = genId;
    const idNum: number = new Date().getTime();
    if (idNum == _self.lastIdNum) {
        _self.lastCount += 1;
        return numToRadix62(idNum) + numToRadix62(_self.lastCount);
    } else {
        _self.lastIdNum = idNum;
        _self.lastCount = 0;
        return numToRadix62(idNum);
    }
}
genId.lastIdNum = 0;
genId.lastCount = 0;

function waitUntil(isEndFn: () => boolean, tickStep?: number): Promise<void> {
    if (isEndFn()) {
        return Promise.resolve();
    } else {
        tickStep = tickStep || 25;
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                resolve(waitUntil(isEndFn, tickStep));
            }, tickStep);
        });
    }
}

let initedList: {
    delegatePageUrl: string;
    clientId: number;
    client: CrossClient;
}[] = [];

export interface CrossClient {
    call(...args: any[]): Promise<any>;
    destroy(): any;
}

let lastClientId = 0;

function createCrossClient(delegatePageUrl: string): CrossClient {
    const matched = initedList.filter(function (record) {
        return record.delegatePageUrl == delegatePageUrl;
    })[0];
    if (matched) {
        return matched.client;
    } else {
        const iframe = document.createElement("iframe");

        // api代理页面是否就绪
        let ready = false;

        //请求回调cache
        const callbackMap: {
            [key: string]: any;
        } = {};

        //event 参数中有 data 属性，就是父窗口发送过来的数据
        window.addEventListener("message", function (evt) {
            if (delegatePageUrl.startsWith(evt.origin)) {
                const msg: Msg = evt.data;
                if (MsgType.Notify == msg.type && msg.payload) {
                    const resp = msg.payload;
                    switch (resp.key) {
                        case NotifyKey.Ready:
                            ready = true;
                            break;
                        default:
                            break;
                    }
                } else if (MsgType.Response == msg.type && msg.payload) {
                    const resp = msg.payload;
                    const callback = callbackMap[resp.reqId];
                    callback && callback(resp.errMsg, resp.result);
                    delete callbackMap[resp.reqId];
                }
            }
        }, false);

        const delegateFunction = function (...args: any[]) {
            //等待api代理页面就绪之后再执行
            return waitUntil(function () {
                return !!iframe.contentWindow && ready;
            }).then(function () {
                //api代理页面已经就绪，开始发送消息
                const reqId = genId();
                iframe.contentWindow!.postMessage({
                    type: MsgType.Request,
                    payload: {
                        reqId: reqId,
                        params: args
                    }
                }, delegatePageUrl);
                return new Promise(function (resolve, reject) {
                    callbackMap[reqId] = function (errMsg: string, result: any) {
                        if (errMsg) {
                            reject(errMsg);
                        } else {
                            resolve(result);
                        }
                    };
                });
            });
        };

        let clientId = ++lastClientId;

        const destroyFunction = () => {
            document.body.removeChild(iframe);
            initedList = initedList.filter((item) => {
                return item.clientId !== clientId;
            });
        };
        let client = {
            call: delegateFunction,
            destroy: destroyFunction
        };
        initedList.push({
            delegatePageUrl: delegatePageUrl,
            clientId: clientId,
            client: client
        });
        iframe.style.display = "none";
        iframe.setAttribute("src", delegatePageUrl);
        document.body.appendChild(iframe);

        return client;
    }
}

export {
    createCrossClient
};
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { createCrossClient, CrossClient } from './cross-delegate-client';

const NATIVE_SERVER_NAME = "localhost.gooseott.com";

let crossClient: CrossClient | undefined;

export function getOrCreateClient(serverName: string) {
    if (!crossClient) {
        crossClient = createCrossClient(
            `https://${serverName}:37141/cross-origin-delegate.html`
        );
    }
    return crossClient;
}

const TEMPLATE = "classic";
const PLATFORMS = ["tizen", "webos"];

enum TemplateType {
    Live = "1",
    Vod = "2",
    LiveVod = "3",
}

const TEMPLATE_TYPES: TemplateType[] = [TemplateType.Live, TemplateType.Vod, TemplateType.LiveVod];

function getConnectorOfTemplateType(templateType: TemplateType) {
    return {
        [TemplateType.Live]: "live",
        [TemplateType.Vod]: "vod",
        [TemplateType.LiveVod]: "live-vod",
    }[templateType];
}

function getNameSuffixOfTemplateType(templateType: TemplateType) {
    return {
        [TemplateType.Live]: "Live",
        [TemplateType.Vod]: "Vod",
        [TemplateType.LiveVod]: "Live_Vod",
    }[templateType];
}

interface TemplateFile {
    templateName: string;
    templateType: string;
    previewPic: string;
    tplFeature: string;
}

interface ChangeLog {
    lang: string;
    content: string;
}

interface Color {
    name: string;
    tag: string;
    value: string;
}

interface Theme {
    name: string;
    tag: string;
    image: string;
    previews: string[];
    colors: Color[];
}

interface VersionFile {
    templateFeatures: ChangeLog[];
    themes: Theme[];
    webappPackage: string;
    shellPackage: string;
}


async function fetchFile(url: string): Promise<Blob> {
    let resp = await window.fetch(url);
    if (resp.ok) {
        return resp.blob();
    } else {
        throw new Error(`Fetch file failed, url: ${url}`);
    }
}

async function fetchInternetResource(originalUrl: string): Promise<Blob> {
    let serverName = NATIVE_SERVER_NAME;
    const crossClient = getOrCreateClient(serverName);
    let url = encodeURIComponent(originalUrl);
    url = `https://${serverName}:37141/api/proxy?url=${url}`;
    let blob = await crossClient.call('ajax', url, {}, {
        method: "GET",
        respType: "file"
    }) as any;
    return blob;
}

export async function startBuild(form: {
    repoAddr: string;
    buildBranch: string;
    baselineVersion: string;
    changeLog: string;
}) {
    let { repoAddr, buildBranch, baselineVersion, changeLog } = form;
    const zip = new JSZip();
    let templateResp = await fetchFile(`/templates/${TEMPLATE}/template.json`);
    let templateFile: TemplateFile = JSON.parse(await templateResp.text());
    let previewPic = templateFile.previewPic;
    //下载模板预览图
    let previewPicFile = await fetchFile(`/templates/${TEMPLATE}/${previewPic}`);
    let versionResp = await fetchFile(`/templates/${TEMPLATE}/versions/version.json`);
    let versionFile: VersionFile = JSON.parse(await versionResp.text());
    let previewFiles: Record<string, Blob> = {};
    for (let theme of versionFile.themes) {
        for (let preview of theme.previews) {
            if (!previewFiles[preview]) {
                //下载版本预览图
                let versionResp = await fetchFile(`/templates/${TEMPLATE}/versions/${preview}`);
                previewFiles[preview] = versionResp;
            }
        }
    }
    let artifacts: Record<string, Blob> = {};
    let webappPackage = `goose-smarttv-web-${baselineVersion}-latest.zip`;
    //下载webapp制品包
    artifacts[webappPackage] = await fetchInternetResource(`${repoAddr}/goose-smarttv-web/goose-smarttv-web-${buildBranch}-latest.zip`);
    for (let platform of PLATFORMS) {
        let shellPackage = `${platform}-app-shell-${baselineVersion}-latest.zip`;
        //下载终端app壳包
        artifacts[shellPackage] = await fetchInternetResource(`${repoAddr}/goose-smarttv-web/${platform}-app-shell-${buildBranch}-latest.zip`);
    }
    //添加版本修改日志
    versionFile.templateFeatures = [{
        lang: "en",
        content: changeLog
    }];
    versionFile.webappPackage = webappPackage;
    for (let platform of PLATFORMS) {
        let shellPackage = `${platform}-app-shell-${baselineVersion}-latest.zip`;
        for (let templateType of TEMPLATE_TYPES) {
            let connector = getConnectorOfTemplateType(templateType);
            let nameSuffix = getNameSuffixOfTemplateType(templateType);
            templateFile.templateType = templateType;
            templateFile.templateName = TEMPLATE.slice(0, 1).toUpperCase() + TEMPLATE.slice(1) + "_" + nameSuffix;
            //添加模板配置文件
            zip.file(`baseline/${platform}/smarttv-${platform}-${TEMPLATE}-${connector}/template.json`, JSON.stringify(templateFile, undefined, 4));
            //添加模板预览图
            zip.file(`baseline/${platform}/smarttv-${platform}-${TEMPLATE}-${connector}/${previewPic}`, previewPicFile);
            //调整壳包文件名
            versionFile.shellPackage = shellPackage;
            //添加版本配置文件
            zip.file(`baseline/${platform}/smarttv-${platform}-${TEMPLATE}-${connector}/versions/${baselineVersion}/version.json`, JSON.stringify(versionFile, undefined, 4));
            //添加版本预览图
            for (let preview in previewFiles) {
                zip.file(`baseline/${platform}/smarttv-${platform}-${TEMPLATE}-${connector}/versions/${baselineVersion}/${preview}`, previewFiles[preview]);
            }
            //添加webapp制品包
            zip.file(`baseline/${platform}/smarttv-${platform}-${TEMPLATE}-${connector}/versions/${baselineVersion}/${webappPackage}`, artifacts[webappPackage]);
            //添加终端app壳包
            zip.file(`baseline/${platform}/smarttv-${platform}-${TEMPLATE}-${connector}/versions/${baselineVersion}/${shellPackage}`, artifacts[shellPackage]);
        }
    }
    zip.generateAsync({ type: 'blob' }).then((blob) => {
        saveAs(blob, `baseline-${baselineVersion}.zip`);
    });
}
